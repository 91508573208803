<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.59961 7.2C3.59961 6.53726 4.09015 6 4.69526 6H19.304C19.9091 6 20.3996 6.53726 20.3996 7.2C20.3996 7.86274 19.9091 8.4 19.304 8.4H4.69526C4.09015 8.4 3.59961 7.86274 3.59961 7.2Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.91701 6.00351C5.46739 5.95765 5.95074 6.36663 5.9966 6.91701L6.9966 18.917C6.9989 18.9446 7.00006 18.9723 7.00006 19.0001C7.00006 19.2653 7.10541 19.5196 7.29295 19.7072C7.48048 19.8947 7.73484 20.0001 8.00005 20.0001H16.0001C16.2653 20.0001 16.5196 19.8947 16.7072 19.7072C16.8947 19.5196 17.0001 19.2653 17.0001 19.0001C17.0001 18.9723 17.0012 18.9446 17.0035 18.917L18.0035 6.91701C18.0494 6.36663 18.5327 5.95765 19.0831 6.00351C19.6335 6.04937 20.0425 6.53272 19.9966 7.0831L18.9997 19.0458C18.9878 19.8249 18.6732 20.5696 18.1214 21.1214C17.5588 21.684 16.7957 22.0001 16.0001 22.0001H8.00005C7.2044 22.0001 6.44134 21.684 5.87873 21.1214C5.32693 20.5696 5.01228 19.8249 5.0004 19.0458L4.00351 7.0831C3.95765 6.53272 4.36663 6.04937 4.91701 6.00351Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V4H10L10 7C10 7.55228 9.55228 8 9 8C8.44772 8 8 7.55228 8 7V4C8 3.46957 8.21071 2.96086 8.58579 2.58579Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconTrash'
}
</script>